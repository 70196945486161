import React from "react";
import Layout from "@Components/Layout";
import styled from "styled-components";
import { Theme } from "@Styles/Theme";
import swipe from "@Images/pictos/swipe.png";
import { useStaticQuery, graphql } from "gatsby";
import GalleryImage from "@Components/GalleryImage";
import ReactDOM from "react-dom";
import { throttle } from "lodash";
import SEO from "@Components/SEO";

const ProjectsContactWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  z-index: 5000;
  position: relative;
  z-index: 5000;
  transition: 0.5s cubic-bezier(0.12, 0.97, 0, 0.97);
`;

const ProjectsWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4000;
  padding: 1rem 8rem;
  overflow-x: scroll;
  overflow-y: hidden;
  background-color: ${Theme.colors.red};
  transition: 0.5s cubic-bezier(0.12, 0.97, 0, 0.97);

  &::-webkit-scrollbar-track {
    background-color: ${Theme.colors.red};
  }

  &::-webkit-scrollbar {
    width: 10px;
    background-color: ${Theme.colors.red};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${Theme.colors.darkRed};
    border-radius: 5px;
  }
`;

const Slider = styled.div`
  height: 100%;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  // gap: 2rem;
`;

export default function Projects() {
  const projectsContainerRef = React.useRef();
  const parentWrapperRef = React.useRef();
  const sliderRef = React.useRef();
  const isMobile = false;
  const isDownRef = React.useRef(false);
  const startXRef = React.useRef();
  const scrollLeftRef = React.useRef();

  let html;
  let { projects, config } = useStaticQuery(graphql`
    query {
      projects: allContentfulProject(filter: { node_locale: { eq: "en-US" } }) {
        edges {
          node {
            slug
            title
            thumbnail {
              fixed {
                src
                aspectRatio
                srcSet
                height
                width
              }
            }
            link
            skills
            position
          }
        }
      }
      config: contentfulConfig(configuration: { eq: "Site configuration" }) {
        displayProjectsByPosition
      }
    }
  `);

  React.useEffect(() => {
    html = document.querySelector("html");
    const slider = projectsContainerRef.current;

    slider.addEventListener("mousedown", (e) => {
      isDownRef.current = true;
      startXRef.current = e.pageX - slider.offsetLeft;
      scrollLeftRef.current = slider.scrollLeft;
    });
    slider.addEventListener("mouseleave", () => {
      isDownRef.current = false;
    });
    slider.addEventListener("mouseup", () => {
      isDownRef.current = false;
    });
    slider.addEventListener("mousemove", (e) => {
      if (!isDownRef.current) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startXRef.current) * 2;
      slider.scrollLeft = scrollLeftRef.current - walk;
    });

    constructGrid();
    window.addEventListener("wheel", horizontalScroll, { passive: true });
    return () => {
      window.removeEventListener("wheel", horizontalScroll);
    };
  }, []);

  const constructGrid = () => {
    const imagesArray = [];
    let p = [...projects.edges];
    if (config.displayProjectsByPosition) {
      p = [...p.sort((a, b) => a.node.position - b.node.position)];
    }
    p.forEach((item) => {
      const image = item.node.thumbnail.fixed;
      if (item.node.title != "Sample") {
        imagesArray.push(
          React.createElement(GalleryImage, {
            fluid: image,
            elemX: image.width,
            elemY: image.height,
            args: item.node.skills ? item.node.skills : [],
            title: item.node.title,
            slug: item.node.slug,
            hasProjectPage: item.node.link,
          })
        );
      }
    });

    const spaceDiv = React.createElement("div", {
      style: { width: "80px", height: "100%" },
    });
    imagesArray.push(spaceDiv);
    ReactDOM.render(imagesArray, sliderRef.current);
  };

  const horizontalScroll = throttle((e) => {
    projectsContainerRef.current.scrollLeft += e.deltaY;
  }, 25);

  return (
    <Layout
      menuOpen={true}
      menuVisible={true}
      location={"projects"}
      textIsRed={false}
    >
      <SEO title="Projets" />
      <ProjectsContactWrapper ref={parentWrapperRef} isMobile={isMobile}>
        <ProjectsWrapper
          className="projectsWrapper"
          ref={projectsContainerRef}
          style={{
            cursor: `url(${swipe}), auto`,
          }}
          isMobile={isMobile}
        >
          <Slider ref={sliderRef} />
        </ProjectsWrapper>
      </ProjectsContactWrapper>
    </Layout>
  );
}
